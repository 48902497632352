import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import { info } from "../data/cookies-policy"
import parse from "react-html-parser"

export default function CookiesPolicy() {
  const path = [{ label: "Inicio", to: "/" }, { label: "Política de cookies" }]

  const renderInfo = () => {
    return (
      info &&
      info.map(item => {
        return parse(item.content)
      })
    )
  }

  return (
    <Layout>
      <Hero image="/imgs/quienes-somos.jpg" text="Política de cookies" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock title="Política de cookies" icon="fas fa-edit" />
        <div className="CookiesPolicy">
          {renderInfo()}
        </div>
      </div>
    </Layout>
  )
}
