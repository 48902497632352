export const info = [
  {
    content: `<h3 class="title-lateral">INFORMACIÓN SOBRE <i>COOKIES</i></h3> <p>Debido a la entrada en vigor de la referente modificación de la “Ley de Servicios de la Sociedad de la Información”
    (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de
    todas las páginas web que usan <i>cookies</i> prescindibles, antes de que éste navegue por ellas.</p>`,
  },
  {
    content: `<h3 class="title-lateral">¿QUÉ SON LAS <i>COOKIES</i>?</h3> <p>Las <i>cookies</i> y otras tecnologías similares tales como local shared objects, flash <i>cookies</i> o píxeles, son herramientas
    empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer
    un correcto funcionamiento del sitio.</p> <p>Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como
    sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le
    interesan, etc.</p>`,
  },
  {
    content: `<h3 class="title-lateral"><i>COOKIES</i> AFECTADAS POR LA NORMATIVA Y <i>COOKIES</i> EXCEPTUADAS</h3> <p>Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.</p>`,
  },
  {
    content: `<h3 class="title-lateral">¿QUÉ TIPOS DE <i>COOKIES</i> EXISTEN?</h3>
    <p>Sobre los tipos de <i>cookies</i>, existen cinco grandes grupos:</p>
    <ul class="botones espaciado">
        <li><i>Cookies</i> analíticas: recogen información del uso que se realiza del sitio web.</li>
        <li><i>Cookies</i> sociales: son aquellas necesarias para redes sociales externas.</li>
        <li><i>Cookies</i> de afiliados: permiten hacer un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato de afiliación (empresas de afiliación).</li>
        <li><i>Cookies</i> de publicidad y comportamentales: recogen información sobre las preferencias y elecciones personales del usuario (retargeting).</li>
        <li><i>Cookies</i> técnicas y funcionales: son las estrictamente necesarias para el uso del sitio web y para la prestacióndel servicio contratado.</li>
    </ul>`,
  },
  {
    content: `<h3 class="title-lateral"><i>COOKIES</i> QUE SE UTILIZAN EN ESTE SITIO WEB</h3><div id="ot-sdk-cookie-policy"></div><button id="ot-sdk-btn" class="ot-sdk-show-settings">Ajustes de cookies</button>`,
  },
  /*{
    content: `<h3 class="title-lateral">REVOCACIÓN DEL CONSENTIMIENTO PARA INSTALAR <i>COOKIES</i> - COMO ELIMINAR LAS <i>COOKIES</i> DEL NAVEGADOR</h3>
    <p><strong>Chrome</strong></p>
    <ul class="botones espaciado">
        <li>Selecciona el icono de Herramientas.</li>
        <li>Haz clic en Configuración.</li>
        <li>Haz clic en Mostrar Opciones Avanzadas.</li>
        <li>En la sección "Privacidad" haz clic en Configuración de contenido.
            <ul class="botones espaciado">
                <li>Eliminar <i>cookies</i>: Haz clic en Todas las <i>cookies</i> y los datos de sitios...</li>
                <li>No permitir que se almacenen <i>cookies</i>.</li>
            </ul>
        </li>
        <li>Haz clic en Eliminar datos de navegación (vaciar la Caché).</li>
        <li>Cierra y reinicia el navegador.</li>
    </ul>
    <p><strong>Internet Explorer. Versión 11</strong></p>
    <ul class="botones espaciado">
        <li>Selecciona Herramientas | Opciones de Internet.</li>
        <li>Haz clic en la ficha General.</li>
        <li>En la sección "Historial de exploración", haz clic en Eliminar el historial de exploración al salir.</li>
        <li>Seleccionar Eliminar archivos.</li>
        <li>Seleccionar Eliminar <i>cookies</i>.</li>
        <li>Haz clic en Eliminar.</li>
        <li>Haz clic en Aceptar.</li>
        <li>Cierra y reinicia el navegador.</li>
    </ul>
    <p>Puede aceptar o rechazar las <i>cookies</i> individualmente en las Preferencias de Firefox, en la sección Historial disponible en Herramientas &gt; Opciones &gt; Privacidad.</p>
    <p>Para más información sobre Mozilla Firefox pulse aquí: <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies" target="_blank"> https://www.mozilla.org/es-ES/privacy/websites/#cookies</a></p>
    <p><strong>Safari Versión 5.1</strong></p>
    <ul class="botones espaciado">
        <li>Selecciona el icono de Safari / Editar | Restaurar Safari.</li>
        <li>Selecciona las siguientes casillas de verificación: Borrar el historial, Eliminar todos los datos de sitio web.</li>
        <li>Haz clic en Restablecer.</li>
        <li>Cierra y reinicia el navegador.</li>
    </ul>
    <p><strong>Opera</strong></p>
    <p>Opciones - Avanzado - <i>Cookies</i>.<br>
    Las opciones de <i>cookies</i> controlan el modo en que Opera los maneja y por lo tanto su aceptación o rechazo.</p>
    <p>Para más información sobre Ópera pulse aquí: <a href="http://help.opera.com/Linux/10.60/es-ES/cookies.html" target="_blank"> http://help.opera.com/Linux/10.60/es-ES/cookies.html</a></p>
    <p><strong>Otros navegadores</strong></p>
    <p>Consulte la documentación del navegador que tenga instalado.</p>`,
  },*/
]
